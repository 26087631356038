import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

createApp(App).use(router).mount("#app");

function ccc() {
  document.getElementById("popup_inst").style.display = "none";
  localStorage.setItem("no_inst_popup", 1);
}

document.getElementById("popctrl").addEventListener("click", (e) => {
  e.preventDefault();
  ccc();
});
function gourl() {
  location.href =
    "https://www.childu.co.kr/campus/sub/Contact%20Us/schedule.asp";
}
